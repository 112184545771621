import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Link, NavLink } from 'react-router-dom';
//import DynamicIcon from 'elements/DynamicIcon.js';

import { getWebsite } from 'utils/WebsiteUtils.js';
import { getValueFromParameterMap } from 'cms/NBossCMS.js';

export default function Menu(props) {
    return (
        <div className={'flex flex-row flex-wrap items-center gap-1.5 lg:gap-2 2xl:gap-3 ' + (props.className || '')}>
            {getValueFromParameterMap(getWebsite().globalContent, 'menu') && getValueFromParameterMap(getWebsite().globalContent, 'menu').itemList.map((item, index) =>
                <>
                    {index !== 0 && <div className='h-3 w-[2px] bg-oszsz-gray-light' />}
                    <MenuBlockMainItem key={item.id} data={item} />
                </>
            )}
        </div>
    );
}

function MenuBlockMainItem(props) {
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const className = 'text-oszsz-blue-dark group-hover:text-oszsz-blue py-4 text-xs lg:text-sm xl:text-xs 2xl:text-sm font-bold uppercase leading-none tracking-wide transition'

    return (
        <div className={'relative flex group ' + (props.className || '')} onMouseEnter={() => setDropdownOpen(true)} onMouseLeave={() => setDropdownOpen(false)}>

            {console.log(props.data)}

            {props.data.targetType === 'ExternalUrl' && <a className={className} href='https://egyesuletonline.hu/login/sesz' target={props.data.targetOpenType}>{props.data.title}</a>}
            {props.data.targetType !== 'ExternalUrl' && <NavLink exact={true} activeClassName='' className={className} to={props.data.fullPath}>{props.data.title}</NavLink>}

            {props.data.childList ? <div className='absolute w-full bottom-0 flex justify-center'>
                <MenuBlockItemDropdown className='absolute min-w-full top-0' open={dropdownOpen} level={props.data.level}>
                    {props.data.childList.map((item, index) => (<MenuBlockSubItem key={index} data={item} collapse={!dropdownOpen} enableCollapse />))}
                </MenuBlockItemDropdown>
            </div> : null}

        </div>
    );
}

function MenuBlockItemDropdown(props) {
    const animationOrigin = props.animationOrigin || 'y';
    return (
        <motion.div
            className={'flex flex-col rounded-lg shadow-xl px-6 py-2 ' + (props.level % 2 !== 0 ? 'bg-oszsz-blue-dark ' : 'bg-oszsz-blue-dark ') + (props.className || '')}
            initial='hidden'
            animate={props.open ? 'visible' : (props.collapse && props.enableCollapse ? 'collapse' : 'hidden')}
            variants={{
                visible: { opacity: 1, x: 0, y: 0, display: 'flex' },
                hidden: { opacity: 0, x: animationOrigin === 'y' ? 0 : 20, y: animationOrigin === 'y' ? 20 : 0, transitionEnd: { display: 'none' } },
                collapse: { opacity: 0, x: 0, y: 0, transitionEnd: { display: 'none' } }
            }}>
            {props.children}
        </motion.div>
    );
}

function MenuBlockSubItem(props) {
    return (
        <div className={'flex flex-col ' + (props.className || '')}>

            <Link className={'flex flex-col justify-center text-center gap-0.5 tracking-wide whitespace-nowrap transition text-white opacity-75 hover:opacity-100 ' + (props.data.level < 3 ? 'uppercase font-semibold text-xs py-2' : 'text-xxs py-1')} to={props.data.fullPath}>
                {props.data.title}
            </Link>

            {props.data.childList ? <div className='flex flex-col'>
                {props.data.childList.map((item, index) => (<MenuBlockSubItem key={index} data={item} collapse={props.collapse} enableCollapse={props.enableCollapse} />))}
            </div> : null}

        </div>
    );
}